import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"
import {Container} from 'react-bootstrap'
import { Link } from "gatsby"
import Obfuscate from 'react-obfuscate'

const PrivacyPolicy = () => (
  <>
    <Header />
    <SEO title="Privacy Policy" />
      <Container className="mt-2">
        <h1>Opsmorph’s Privacy Policy</h1>
        <hr />
        <p>This privacy policy will explain how Opsmorph uses the personal data we collect from you when you use our website. This privacy policy is an adaptation of the EU GDPR template that can be found here <Link to="https://gdpr.eu/privacy-notice/" className='nav-link p-0 d-inline'>https://gdpr.eu/privacy-notice/</Link></p>
        <h4>What data do we collect?</h4>
        <p>Opsmorph collects the following data: Personal identification information name, email address, phone number, and job title. </p>
        <h4>How do we collect your data?</h4>
        <p>You directly provide Opsmorph with most of the data we collect. We collect data and process data when you:</p> 
        <ol>
            <li>Register online or place an order for any of our products or services.</li>
            <li>Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.</li>
            <li>Use or view our website via your browser’s cookies. </li>
        </ol>
        <p>Opsmorph may also receive your data indirectly from the following sources when you interact with us online via:</p> 
        <ol>
            <li>Twitter</li>
            <li>LinkedIn</li>
            <li>Email</li>
        </ol>
        <h4>How will we use your data?</h4>
        <p>Opsmorph collects your data so that we can:</p> 
        <ol>
            <li>Process your order and manage your account. </li>
            <li>Email you with special offers on other products and services we think you might like.</li>
        </ol>
        <p>When Opsmorph processes your order, it may send your data to, and also use the resulting information from, credit reference agencies to prevent fraudulent purchases.</p>
        <h4>How do we store your data?</h4>
        <p>Opsmorph securely stores your data in cloud databases. Opsmorph will keep your personal data for three years after the data is collected. Once this time period has expired, or sooner if you request us to do so, we will delete your data via automated data retention policies in our database.</p>
        <h4>Marketing</h4>
        <p>Opsmorph would like to send you information about products and services of ours that we think you might like. If you have agreed to receive marketing, you may always opt out later. You have the right at any time to stop Opsmorph from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please email <Obfuscate email="info@opsmorph.com"/></p>
        <h4>What are your data protection rights?</h4>
        <p>Opsmorph would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p> 
        <ol>
            <li>The right to access – You have the right to request Opsmorph for copies of your personal data. We may charge you a small fee for this service.</li>
            <li>The right to rectification – You have the right to request that Opsmorph correct any information you believe is inaccurate. You also have the right to request Opsmorph to complete the information you believe is incomplete.</li>
            <li>The right to erasure – You have the right to request that Opsmorph erase your personal data, under certain conditions.</li>
            <li>The right to restrict processing – You have the right to request that Opsmorph restrict the processing of your personal data, under certain conditions.</li>
            <li>The right to object to processing – You have the right to object to Opsmorph’s processing of your personal data, under certain conditions.</li>
            <li>The right to data portability – You have the right to request that Opsmorph transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
        </ol>
        <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email <Obfuscate email="info@opsmorph.com" /></p>
        <h4>Cookies</h4>
        <p>Cookies are text files placed on your computer to collect standard Internet log information and visitor behaviour information. When you visit our websites, we may collect information from you automatically through cookies or similar technology. For further information, visit <Link to="https://allaboutcookies.org" className='nav-link p-0 d-inline'>allaboutcookies.org</Link></p>
        <h4>How do we use cookies?</h4>
        <p>Opsmorph uses cookies in a range of ways to improve your experience on our website, including:</p> 
        <ol>
            <li>Keeping you signed in where you access authenticated content</li>
            <li>Understanding how you use our website</li>
        </ol>
        <h4>What types of cookies do we use?</h4>
        <p>There are several different types of cookies, however, our website uses:</p> 
        <ol>
            <li>Functionality – Opsmorph uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and what location you are in. A mix of first-party and third-party cookies are used.</li>
            <li>Monitoring site use – Opsmorph uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address.</li>
        </ol>
        <h4>How to manage cookies</h4>
        <p>You can set your browser not to accept cookies, and the <Link to="https://allaboutcookies.org" className='nav-link p-0 d-inline'>allaboutcookies.org</Link> website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.</p>
        <h4>Privacy policies of other websites</h4>
        <p>The Opsmorph website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>
        <h4>Changes to our privacy policy</h4>
        <p>Opsmorph keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 7 November 2020.</p>
        <h4>How to contact us</h4>
        <p>If you have any questions about Opsmorph’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us. 
Email us at <Obfuscate email="info@opsmorph.com" /></p>
        <h4>How to contact the appropriate authority</h4>
        <p>Should you wish to report a complaint or if you feel that Opsmorph has not addressed your concern in a satisfactory manner, you may contact the Information Commissioner’s Office. For details see the website <Link to="https://ico.org.uk/global/contact-us/" className='nav-link p-0 d-inline'>https://ico.org.uk/global/contact-us/</Link></p>
      </Container>
      <Footer />
  </>
)

export default PrivacyPolicy